import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-16a.png'
import image2 from 'images/project-16b.png'
import image3 from 'images/project-16c.png'
import image4 from 'images/project-16d.png'
import image5 from 'images/project-16e.png'
import image6 from 'images/project-16f.png'
import image7 from 'images/project-16g.png'
import image8 from 'images/project-16h.png'
import image9 from 'images/project-16i.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Elsdon home upgrade &mdash; Feeling like &lsquo;a home again&rsquo;"
      meta={[
        {
          name: 'description',
          content: '',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Elsdon home upgrade &mdash; Feeling like &lsquo;a home again&rsquo;"
      intro="In late 2020, the Trust took on a deferred maintenance project for an elderly homeowner."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            The house had been in the family for many years, and the owner was struggling to maintain it. Her family wanted to help and approached the Ryhaven Trust to lead the work, with their whānau as a willing team of volunteers.
          </Paragraph>
          <Paragraph>
            &ldquo;Since our Dad passed away,&rdquo; family member Felila shared, &ldquo;things changed and the family went in different directions. The house became more run down and hardly anyone visited.
          </Paragraph>
          <Paragraph>
            Since then, the kitchen has been replaced with a modern one, and the bathroom refurbished with new shower, joinery and floor coverings. With all services needing replacement, this included the installation of a new hot water cylinder.
          </Paragraph>
          <Paragraph>
            &ldquo;With the new lease of life that has been given to the family home, it will finally become a home again with lots of visitors and laughter &ndash; just as a home should be.&rdquo; says a family member.
          </Paragraph>
          <Paragraph>
            Whilst Bernard, the Trust&rsquo;s lead builder, tackled tasks requiring specialist skills such as replacing window sashes, the family were exemplary, rolling up their sleeves to prepare and paint the house inside and out.
          </Paragraph>
          <Paragraph>
            &ldquo;We have learnt so much about renovating and how to fix things. Knowing there are other families waiting to have their houses done after us has given us an extra push to get this done quickly so other families can be blessed with the same&nbsp;overwhelming happiness as us.&rdquo;
          </Paragraph>
          <Paragraph>
            Still on the &lsquo;to do&rsquo; list is a new concrete pathway that will improve pedestrian access to the house.
          </Paragraph>
          <Paragraph>
            &ldquo;Ryhaven Trust is making a huge difference in our Mother&rsquo;s life. She can finally come home and with the support of rails etc, she will be more independent when walking up the driveway... The biggest difference is that the house will feel like a home again. It&rsquo;s been a long time coming.&rdquo;
          </Paragraph>
          <figure>
            <img className={styles.image} src={image8} alt="" />
            <figcaption>Exterior before</figcaption>
          </figure>
          <figure>
            <img className={styles.image} src={image9} alt="" />
            <figcaption>Exterior after</figcaption>
          </figure>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
          <img className={styles.image} src={image4} alt="" />
          <img className={styles.image} src={image5} alt="" />
          <img className={styles.image} src={image6} alt="" />
          <img className={styles.image} src={image7} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
